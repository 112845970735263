import { navigate } from "gatsby"
import React, { useEffect } from "react"
import LoadingView from "../../../components/BaseComponents/LoadingView"
import useFirebaseWrapper from "../../../hooks/useFirebaseWrapper"
import { HostManage } from "../../../modules/local-meetups"
import useValidateMeetupStatus from "../../../modules/local-meetups/hooks/useValidateMeetupStatus"

export default () => {
  const {
    isRegisteredAsHost,
    isRegisteredAsAttendee,
    hostId,
  } = useValidateMeetupStatus()
  const { isLoggedIn } = useFirebaseWrapper()

  useEffect(() => {
    let timer

    if (isLoggedIn === false) {
      timer = setTimeout(() => {
        navigate("/")
      }, 2500)
    }

    if (isRegisteredAsHost === null) {
      return
    }

    if (isRegisteredAsHost === false) {
      timer = setTimeout(() => {
        navigate("/meetups/host/create")
      }, 2500)
    }

    if (isRegisteredAsAttendee === true) {
      timer = setTimeout(() => {
        navigate("/")
      }, 2500)
    }

    return () => clearTimeout(timer)
  }, [isLoggedIn, isRegisteredAsAttendee, isRegisteredAsHost])

  if (isRegisteredAsHost === null) {
    return <LoadingView />
  }

  if (isRegisteredAsAttendee === true) {
    return <LoadingView message="You have registered as an attendee.." />
  }

  if (isRegisteredAsHost === false) {
    return <LoadingView message="You are not registered as a host.." />
  }

  if (!hostId) {
    return <LoadingView />
  }

  return <HostManage hostId={hostId} />
}
